<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Create Target</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/targets">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-form>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              label="Description"
              v-model="record.comment"
              auto-grow
              outlined
              document_lines="1"
              row-height="15"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              v-model="record.Tvalue"
              type="number"
              label="Target Value"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="record.TargetType"
              :items="targetTypeData"
              item-text="name"
              item-value="value"
              label="Target Metric"
            ></v-autocomplete>

            <!-- <v-autocomplete
              dense
              outlined
              v-model="record.UoM"
              :items="uoms"
              item-text="UomName"
              item-value="id"
              label="Target Metric"
            ></v-autocomplete> -->
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="record.RecurPat"
              :items="repeatData"
              item-text="name"
              item-value="value"
              label="Repeat"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <DatePicker
              @date="setStartDate"
              :myDate="record.StartDate"
              :title="`From Date`"
            ></DatePicker>
          </v-col>
          <v-col cols="6">
            <DatePicker
              @date="setEndDate"
              :myDate="record.EndDate"
              :title="`To Date`"
            ></DatePicker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-checkbox
              v-model="record.selectAllISlP"
              label="Select All Employees"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-card-text>OR</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              outlined
              dense
              v-model="record.employees"
              :items="salesEmployees"
              attach
              chips
              clearable
              @input="searchInput = null"
              :search-input.sync="searchInput"
              deletable-chips
              label="Select Sales Employees"
              item-text="SlpName"
              item-value="SlpCode"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-checkbox
              dense
              outlined
              v-model="record.selectAllItems"
              label="Select All Items"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-card-text>OR</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              dense
              outlined
              v-model="record.skus"
              :items="itemsData"
              attach
              chips
              clearable
              @input="searchInput = null"
              :search-input.sync="searchInput"
              deletable-chips
              label="SKUs"
              item-text="ItemName"
              item-value="ItemCode"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    itemsData: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      record: {},
      searchInput: null,
      salesEmployees: [],
      uoms: [],
      loader: false,
      modal2: false,
      time: null,
      dialog: false,
      selectAllISlP: false,
      selectAllItems: false,
      repeatData: [
        { name: "Never", value: "N" },
        // { name: "Weekly", value: "W" },
        { name: "Daily", value: "D" },

        // { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Quarterly", value: "Q" },
        { name: "Annually", value: "A" },
      ],
      targetTypeData: [
        { name: "Quantity", value: "Q" },
        { name: "Amount", value: "A" },
      ],
      // targetMetric: [
      //   { name: "Quantity", value: "0" },
      //   { name: "Amount", value: "A" },
      // ],
    };
  },
  watch: {
    // selectAllISlP(value) {
    //   if (value) {
    //     this.record.employees = this.salesEmployees.map(
    //       (employee) => employee.SlpCode
    //     );
    //   } else {
    //     this.record.employees = [];
    //   }
    // },
    selectAllItems() {
      if (this.selectAllItems) {
        this.record.skus = this.itemsData.map((item) => item.ItemCode);
      } else {
        this.record.skus = [];
      }
    },
  },
  methods: {
    setStartDate(date) {
      this.record.StartDate = date;
    },
    setEndDate(date) {
      this.record.EndDate = date;
    },
    sendData() {
      console.log(this.record);

      this.loader = true;
      this.$emit("data", this.record);
    },
    getEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUom() {
      const self = this;
      this.$store
        .dispatch("get", `/uom`)
        .then((res) => {
          // console.log(res.ResponseData, "uoms");
          self.uoms = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
    this.getUom();
  },
};
</script>

<style lang="scss" scoped>
</style>