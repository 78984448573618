<template>
  <v-container fluid>
    <!-- editor -->
    <editor
      :editMode="editMode"
      :itemsData="itemsData"
      :initial="dataItem"
      @data="save"
    ></editor>
    <snackbar ref="snackbar"></snackbar>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    itemsData: [],
    editMode: false,
  }),
  methods: {
    save(data) {
      const url = "/targets";
      const self = this;
      console.log(data, "data");
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            console.log(res, "r");
            self.$refs.snackbar.show("Created", "green");
            self.$router.push("/targets");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getItems() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/items_data`)
        .then((res) => {
          self.itemsData = res.ResponseData;
          console.log(res.ResponseData);
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getItems();
  },
};
</script>